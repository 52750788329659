import React, { FC, Fragment, useEffect, useState } from 'react';
import { checkFlag } from '@mb/lib';
import { useAbtFeatureFlag } from '@mb/lib/hooks/useAbtFeatureFlag';
import { SplitFeatureFlag, ContentBlocksTreatment } from '@mb/lib/splitio';
import { useSelector, useDispatch } from 'react-redux';
import * as Styled from './AppContainer.styles';
import { BrandSnackbar } from '../../components/BrandSnackbar';
import { ErrorModal } from '../../components/modals/ErrorModal';
import { ExportModal } from '../../components/modals/ExportModal';
import { ProjectModalListener } from '../../components/modals/ProjectsModal';
import { PageSkeleton } from '../../components/PageSkeleton';
import { Snackbar } from '../../components/Snackbar';
import { ColorPickerContainer } from '../../containers/ColorPickerContainer';
import FilterWrapper from '../../containers/FilterContainer/FilterWrapper';
import MaterialsContainer from '../../containers/MaterialsContainer/MaterialsContainer';
import ResultsHeaderWrapper from '../../containers/ResultsHeader/ResultsHeaderWrapper';
import { useCurrentProjectEventListeners } from '../../hooks/useCurrentProjectEventListeners';
import useSwitchCurrentUserProject from '../../hooks/useSwitchCurrentUserProject';
import {
  isCompareTableShownSelector,
  isSearchLiteSelector,
} from '../../redux/selectors/commonSelector';
import { setIsCompareTableShown } from '../../redux/sliceCreators/commonSlice';
import {
  isBannersPage,
  isPartnersPage,
  shouldRenderCompareOnInit,
} from '../../utils/helpers';
import { CompareTableWrapper } from '../CompareTableWrapper/CompareTableWrapper';
import { FixedTopbar } from '../FixedTopbar';
import TopBannersWrapper from '../LaunchesContainer/TopBannersWrapper';
import { SearchFormListener } from '../SearchFormListener';
import { SearchResultsContainer } from '../SearchResultsContainer';
import { CMSModalContainer } from '../SearchResultsContainer/CMSModalContainer';

const isPartnersSearch = isPartnersPage();
const isBannersSearch = isBannersPage();
const shouldShowTopbar = !isPartnersSearch;
const shouldShowFilters = !isPartnersSearch && !isBannersSearch;

export type AppContainerProps = {
  displayAddAllToCartButton?: boolean;
  displayHeaderFilterButton?: boolean;
  displayHeaderFilters?: boolean;
  displayViewOptions?: boolean;
};

const AppContainer: FC<AppContainerProps> = ({
  displayAddAllToCartButton = false,
  displayHeaderFilterButton,
  displayHeaderFilters = false,
  displayViewOptions,
}) => {
  useCurrentProjectEventListeners();

  const { flagState } = useAbtFeatureFlag('abt-test-flag');
  // This is just a temporary test for ABT it will not be displayed in Production and it will later be removed
  const isDevEnv = [
    'uat2.materialbank.com',
    'dev.materialbank.com',
    'uat.materialbank.eu',
    'materialbank.test',
  ].includes(window.location.hostname);

  const isSearchLite = useSelector(isSearchLiteSelector);
  const isCompareTableShown = useSelector(isCompareTableShownSelector);
  const dispatch = useDispatch();
  const { setCurrentUserProject } = useSwitchCurrentUserProject();

  const [displayContentBlocksFlag, setDisplayContentBlocksFlag] =
    useState(false);
  const [isLoadingFeatureFlag, setIsLoadingFeatureFlag] = useState(true);

  useEffect(() => {
    if (shouldRenderCompareOnInit()) {
      dispatch(setIsCompareTableShown(true));
    }
  }, []);

  const shouldShowColors =
    (!isSearchLite || isBannersSearch) && !isPartnersSearch;

  const handleNewProjectSelect = (e: CustomEvent) => {
    const newProjectId =
      e?.detail?.project?.externalId || e?.detail?.project?.project_id;
    if (newProjectId) {
      // @ts-expect-error // TODO: fix types
      setCurrentUserProject(newProjectId, false);
    }
  };

  useEffect(() => {
    window.addEventListener('project-selected', handleNewProjectSelect);
    return () => {
      window.removeEventListener('project-selected', handleNewProjectSelect);
    };
  }, []);

  useEffect(() => {
    checkFlag(SplitFeatureFlag.CONTENT_BLOCKS, ContentBlocksTreatment.ON)
      .then((flag: boolean) => {
        window.sessionStorage.setItem('contentBlocksFlag', flag ? 'on' : 'off');
        setDisplayContentBlocksFlag(flag);
      })
      .catch(() => {
        setDisplayContentBlocksFlag(false);
      })
      .finally(() => {
        setIsLoadingFeatureFlag(false);
      });
  }, []);

  if (isLoadingFeatureFlag) {
    return <PageSkeleton />;
  }

  return (
    <div id="productSearchWrapper" style={{ width: '100%' }}>
      <>
        {/* This is just a temporary test for ABT it will not be displayed in Production and it will later be removed */}
        {flagState.flagValue && isDevEnv && (
          // @ts-expect-error // TODO: fix types
          <h1 style={{ color: flagState.flagValue }}>{flagState.flagValue}</h1>
        )}
      </>
      <Styled.AppWrapper>
        <SearchFormListener />
        {isCompareTableShown ? (
          <CompareTableWrapper />
        ) : (
          <Fragment>
            {!isSearchLite && shouldShowFilters && <TopBannersWrapper />}
            {shouldShowTopbar && (
              // @ts-expect-error // TODO: fix types
              <FixedTopbar
                displayHeaderFilters={displayHeaderFilters || shouldShowFilters}
              >
                <ResultsHeaderWrapper
                  displayAddAllToCartButton={displayAddAllToCartButton}
                  displayHeaderFilterButton={displayHeaderFilterButton}
                  displayViewOptions={displayViewOptions}
                />
                {shouldShowColors && <ColorPickerContainer />}
              </FixedTopbar>
            )}
            {/* Do not remove this Id, it is important for miniPDP correct render */}
            <Styled.ResultsContainer id="searchResultsContainer">
              <FilterWrapper />
              <Styled.ResultsWrapper>
                {displayContentBlocksFlag ? (
                  <SearchResultsContainer />
                ) : (
                  <MaterialsContainer />
                )}
              </Styled.ResultsWrapper>
            </Styled.ResultsContainer>
          </Fragment>
        )}
      </Styled.AppWrapper>
      <Snackbar />
      <BrandSnackbar />
      <ProjectModalListener />
      <ErrorModal dialogWidth={480} />
      <ExportModal />
      <CMSModalContainer />
    </div>
  );
};

export { AppContainer };
