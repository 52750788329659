import React, { useMemo, useState, useCallback } from 'react';
import { InputAdornment } from '@mui/material';
import flatMap from 'lodash/flatMap';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  filterRedundantFacets,
  getParent,
  processLabel,
} from './SearchForFilters.helpers';
import * as Styled from './SearchForFilters.styled';
import { sendAdhocAnalytics } from '../../../api/events';
import { definedTranslation } from '../../../i18n';
import { currentFiltersSelector } from '../../../redux/selectors/filtersSelector';
import { addFilter } from '../../../redux/sliceCreators/filtersSlice';
import { FilterData } from '../../../types';

const displayLimit = 20;

const SearchForFilters = () => {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState<string | null>(null);
  const filters = useSelector(currentFiltersSelector);
  const dispatch = useDispatch();
  const intl = useIntl();

  const filtersList = useMemo(() => {
    const result = flatMap(filters, (parent) =>
      parent.visible === false
        ? []
        : parent.values
            .filter(filterRedundantFacets)
            .map((item: FilterData) => ({
              ...item,
              parentsLabel: parent.label?.split('_parent')?.[0],
              ...getParent(item),
            })),
    );
    return result || [];
  }, [filters]);

  const filterOptions = useCallback(
    (options: FilterData[], { inputValue }: { inputValue: string }) => {
      const filteredList = options.filter((option: FilterData) => {
        const combinedString =
          `${option.label} ${option.parentsLabel}`.toLowerCase();
        return inputValue
          .toLowerCase()
          .split(' ')
          .every((term) => combinedString.includes(term));
      });

      // Sort by first matching index and use length of string as tie breaker
      filteredList.sort((a: FilterData, b: FilterData) => {
        const combinedA = `${a.label} ${a.parentsLabel}`.toLowerCase();
        const combinedB = `${b.label} ${b.parentsLabel}`.toLowerCase();
        const indexA = combinedA.indexOf(inputValue.toLowerCase());
        const indexB = combinedB.indexOf(inputValue.toLowerCase());

        if (indexA !== indexB) {
          return indexA - indexB;
        }

        return combinedA.length - combinedB.length;
      });

      // Limit to show
      return filteredList.slice(0, displayLimit);
    },
    [],
  );

  const handleSelectedFilter = useCallback(
    (filter: FilterData) => {
      dispatch(addFilter(filter));
      setValue(null);
      setInputValue('');
      setOpen(false);
      sendAdhocAnalytics({
        id: [],
        type: 'select_value',
        action: 'search_filter_menu',
        impressions: [filter?.field, filter?.label],
      });
    },
    [dispatch],
  );

  const startAdornment = (
    <InputAdornment position="end">
      <Styled.SearchIcon name="search" dense />
    </InputAdornment>
  );

  return (
    <Styled.OuterWrapper>
      <Styled.AutocompleteWrapper
        disablePortal
        open={open}
        onClose={() => setOpen(false)}
        options={filtersList}
        sx={{ width: '100%' }}
        value={value}
        onChange={(_, filter: any) => {
          handleSelectedFilter(filter);
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
          setOpen(newInputValue.length >= 2);
        }}
        getOptionLabel={(option: FilterData) => option.label}
        PopperComponent={(props) => (
          <Styled.PopperBox
            {...props}
            placement="bottom-start"
            popperOptions={{ placement: 'bottom-start' }}
          />
        )}
        filterOptions={filterOptions}
        renderOption={(props, option: FilterData) => (
          <Styled.Option {...props}>
            {processLabel(option.label)}{' '}
            <span className="parentLabel">&nbsp;| {option.parentsLabel}</span>
          </Styled.Option>
        )}
        renderInput={(params) => (
          <Styled.SearchField
            {...params}
            InputProps={{
              ...params?.InputProps,
              startAdornment,
              endAdornment: undefined,
            }}
            placeholder={intl?.formatMessage(
              definedTranslation.filters.searchFilters,
            )}
            label=""
          />
        )}
      />
    </Styled.OuterWrapper>
  );
};

export { SearchForFilters };
