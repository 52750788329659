import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { CustomIcon } from 'components/CustomIcon';
import palette from 'theme/palette';

export const AutocompleteWrapper = styled(Autocomplete)`
  position: relative;
  z-index: 20;
  margin-top: 12px;
`;

export const SearchField = styled(TextField)`
  width: 220px;
  display: flex;
  margin-right: 12px;
  font-size: 14px;
  .MuiOutlinedInput-root {
    border-radius: 24px;
    background-color: ${palette.grey[50]};
    border-color: ${palette.grey[50]};
    height: 36px;
    padding: 0 8px 0 0 !important;

    &:hover,
    &.Mui-focused {
      .MuiInputAdornment-root:first-of-type svg {
        color: ${palette.primary.main};
      }
    }

    & fieldset {
      border: none !important;
    }
  }
`;

export const SearchIcon = styled(CustomIcon)`
  cursor: pointer;
  color: ${palette.grey.A400};
  margin-right: 0;
`;

export const Option = styled.li`
  padding: 4px 8px !important;
  white-space: nowrap;
  .parentLabel {
    color: ${palette.grey.A400};
  }
`;

export const PopperBox = styled(Popper)`
  &[data-popper-placement='bottom-start'] {
    margin-top: 4px;
  }
  &[data-popper-placement='top-start'] {
    display: none; /* Скрываем Popper, если он пытается разместиться сверху */
  }
`;

export const OuterWrapper = styled.div`
  position: relative;
`;
