import React, { useCallback, useMemo } from 'react';
import { isEuEnv } from '@mb/lib/i18n';
import { SPLIT_EVENTS, SplitTrafficType } from '@mb/lib/splitio';
import { ProductCard } from 'components/Cards/ProductCard';
import {
  ColorVariant,
  ProductCardActionType,
} from 'components/Cards/ProductCard/ProductCard.types';
import sortBy from 'lodash/sortBy';
import { useDispatch } from 'react-redux';
import { FinishGroupItem, ResultCardProps } from './types';
import { pushLearnMoreCtaClickDataLayer } from '../../../../../../../../../MIT/Search/view/frontend/components/MiniPDPContainer/helpers/analytics';
import { sendAnalytics } from '../../../api/events';
import { EngagementTargets } from '../../../config/constants';
import { formatImageSource, getEventsMaterialProps } from '../../../helpers';
import { triggerPreviewFileModal } from '../../../helpers/triggerPreviewFileModal';
import { setProductWithFilePreviewInQueue } from '../../../redux/sliceCreators/searchSlice';
import {
  getIsPaintProductType,
  isSearchPage,
  mapMaterialsForAnalytics,
} from '../../../utils/helpers';
import { buildAnalyticsParamsToPDP } from '../../../utils/helpers/analytics';
import { individualToggle } from '../../Filter/FamilyViewSwitch';

const isSearch = isSearchPage();

const ResultCard = React.memo<ResultCardProps>((props) => {
  const {
    material: product,
    imageSize = 252,
    selectedProject,
    onProductClick,
    onCreateProjectClick,
    onProjectClick,
    projects,
    isMultiselect,
    onMaterialSelect,
    isSelected,
    searchToggle,
    cartItemsIds = [],
    onAddToFavorites,
    isColorway = false,
    isMobile,
    isSmallDesktop,
    isHoverAllowed,
    isOutlined,
    isSearchPage,
    onContextMenuClick,
    onAddToCart,
    itemListId,
    itemListName,
    analyticsEventLocation,
    index,
    userLang = 'en',
    selectedVariants,
    multiselectIds,
    shouldShowVariants,
    onColorVariantsClick,
    isSelectboxDisabled,
    isCompareLimitReached,
    showOnHoverOnlyOvernight,
    isCompactDescription,
    shouldShowHoverSelect = true,
    shouldHideFavoritesButton = false,
    customTopRightButtonLabel,
    customTopRightButtonCallback,
    shouldHideActionButton = false,
    cardStaticLabels,
    loadingCartItemIds,
  } = props;

  const {
    name,
    residentialCommercial,
    priceSign,
    entityId,
    manufacturer,
    manufacturerSku,
    blurhash,
    isInRealTimeStock,
    isNew,
    isGeoRestricted,
    isJustAdded,
    colorwayId,
    childrenCount,
    isCollection,
    isDigital,
  } = product;

  const dispatch = useDispatch();
  const isPaint = getIsPaintProductType(product);
  const isProduct = product?.isProduct;
  const isFinishMaterial = product?.isFinish;
  const urlPrefix = isEuEnv ? `/${userLang}` : '';
  const cardLabel = isColorway ? product?.title : name;
  const { cardLabels, internalLabels } = cardStaticLabels;

  const conditionalLabels = {
    addToCartLabel: isGeoRestricted
      ? cardLabels?.seeSimilarLabel
      : cardLabels?.addToCartLabel,
    saveButtonLabel: customTopRightButtonLabel || cardLabels?.saveButtonLabel,
    checkboxTooltipTitle:
      isCompareLimitReached && isSelectboxDisabled
        ? cardLabels?.checkboxTooltipTitle
        : undefined,
  };
  const finalLabels = { ...(cardLabels || {}), ...conditionalLabels };
  const digitalButtonLabel = product?.hasAttachments
    ? internalLabels?.previewFilesLabel
    : cardLabels?.learnMoreLabel;

  const imageUrl = useMemo(() => {
    const thumbnail = product.thumbnailImageUrl;

    // image from API comes with get param ?width=X, we need to replace that
    // to adjust size to column size
    const defaultSizeImage = thumbnail?.split('?')?.[0];
    const finalImageSize = Math.max(424, imageSize);
    const finalUrl = defaultSizeImage
      ? formatImageSource(defaultSizeImage, finalImageSize, false)
      : null;
    return finalUrl?.src || '';
  }, [product, imageSize]);

  const analyticsProps = useMemo(() => {
    return getEventsMaterialProps(product);
  }, [product]);

  const { memoizedVariants, variantEntityId, variantImageUrl } = useMemo(() => {
    const variants = product.colorVariants?.items;
    const rawImage = imageUrl?.split('?')?.[0];
    const newList = sortBy(variants || [], (obj) => obj.image_url !== rawImage);

    const currentVariantId = selectedVariants?.[product.entityId];
    const currentVariantObj = newList?.find(
      (item) => item.entity_id === currentVariantId,
    );
    const variantImage =
      currentVariantObj?.image_url != null
        ? formatImageSource(currentVariantObj?.image_url, 420, false, 80)?.src
        : null;
    return {
      memoizedVariants: newList,
      variantEntityId: currentVariantId,
      variantImageUrl: variantImage,
    };
  }, [product, imageUrl, selectedVariants]);

  const isInCart = cartItemsIds.includes(variantEntityId || entityId);
  const itemId = variantEntityId || entityId;
  const isParentsShown = searchToggle !== individualToggle;

  const isMultiselected = isSelected || (multiselectIds ?? []).includes(itemId);

  let materialUrl: string;
  const pdpTrackParams: string = buildAnalyticsParamsToPDP({
    product,
    itemListId,
    itemListName,
    index: index !== undefined ? index + 1 : undefined,
  });

  if (colorwayId != null) {
    materialUrl = `${product.url}?activeChild=${colorwayId}&${pdpTrackParams}`;
  } else if (
    searchToggle === individualToggle ||
    isFinishMaterial ||
    !isCollection
  ) {
    materialUrl = `${product.url}?activeChild=${itemId}&${pdpTrackParams}`;
  } else {
    materialUrl = `${product.url}?${pdpTrackParams}`;
  }

  materialUrl = `${urlPrefix}${materialUrl}`;

  const handleFavoritesClick = () => {
    onAddToFavorites?.(
      {
        ...product,
        entityId: itemId || product.entityId,
      },
      index || 0,
    );
  };

  const handleItemSelect = () => {
    if (!isSelectboxDisabled) {
      onMaterialSelect?.({
        ...product,
        entityId: itemId,
      });
    }
  };

  const handleVariantClick = (item: ColorVariant) => {
    onColorVariantsClick?.({
      ...product,
      exactColor: item?.exact_color,
      entityId: item?.entity_id,
      thumbnailImageUrl: item?.image_url,
      parentEntityId: entityId,
    });
  };

  const handleAddToBoxClick = useCallback(() => {
    onAddToCart?.({
      product: {
        ...product,
        entityId: itemId,
        imageUrl: variantImageUrl || product.thumbnailImageUrl,
      },
      productEventData: {
        subtitle1,
        subtitle2: cardLabel,
      },
    });
  }, [product, variantEntityId, variantImageUrl]);

  const handleProductClick = async (
    id?: string,
    isFromVariantsNumber = false,
  ) => {
    // track
    window.splitFactory
      ?.client()
      .track(
        SplitTrafficType.USER,
        SPLIT_EVENTS.SRP_engagement_click,
        undefined,
        {
          srpEngagementTarget: EngagementTargets.QV_OPEN,
        },
      );

    if (isGeoRestricted && !isMobile) {
      await sendAnalytics(
        { id: [String(itemId)], type: 'view', ...analyticsProps },
        1200,
      );
      window.dispatchEvent(
        new CustomEvent('open-restricted-item-modal', {
          detail: {
            title: manufacturer,
            subtitle1,
            subtitle2: cardLabel,
            entityId: itemId,
            imgUrl: variantImageUrl || product.thumbnailImageUrl,
            material: product,
            projectId: selectedProject?.id,
          },
        }),
      );
      return;
    }

    if (isMultiselect) {
      if (!isSelectboxDisabled) {
        onMaterialSelect?.(product);
      }
    } else {
      const newUrl =
        id != null && colorwayId == null
          ? `${urlPrefix}${product.url}?activeChild=${id}`
          : materialUrl;
      onProductClick?.({
        ...product,
        url: newUrl,
        manufacturerSku,
        thumbnailImageUrl: variantImageUrl || imageUrl,
        entityId: variantEntityId || id || itemId,
        isColorApplied: Boolean(variantEntityId),
        index,
      });
    }
  };

  const onCreateProject = useCallback(() => {
    onCreateProjectClick?.({ analyticsEventLocation });
  }, [analyticsEventLocation, onCreateProjectClick]);

  const handleActionButtonClick = async (
    id?: string,
    isFromVariantsNumber = false,
  ) => {
    if (isDigital) {
      const { gaType } = mapMaterialsForAnalytics(
        [product],
        cardLabel,
        'digital_sampling',
        'digital sampling',
      );
      pushLearnMoreCtaClickDataLayer(gaType);

      if ((projects || []).length === 0) {
        dispatch(
          setProductWithFilePreviewInQueue({
            ...product,
            entityId: itemId,
          }),
        );
        onCreateProject?.();
        return false;
      }
      // If attachments exist, open preview files dialog
      if (product?.hasAttachments) {
        await triggerPreviewFileModal({ product });
        return false;
      }
    }

    handleProductClick(id, isFromVariantsNumber)
      .then(() => {})
      .catch((e) => console.log('Failed to handleProductClick', e));
    return false;
  };

  const handleRightClick = () => {
    onContextMenuClick?.({
      ...product,
      entityId: variantEntityId || itemId,
    });
  };

  const handleProjectClick = useCallback(
    (id: number) => {
      onProjectClick?.(id, { analyticsEventLocation });
    },
    [projects, analyticsEventLocation, onProjectClick],
  );

  const usageList =
    residentialCommercial?.length > 0 ? residentialCommercial : null;

  const priceLabel = isPaint ? null : priceSign;
  const availableSubcategories: string[] =
    product.categories?.filter((item: string) => item.includes('>')) ?? [];
  const firstSubcategory = availableSubcategories?.[0]?.split('>')?.pop();
  const lastSubcategory = availableSubcategories?.[
    availableSubcategories.length - 1
  ]
    ?.split('>')
    ?.pop();
  let subcategoryToShow = product.categories?.includes(firstSubcategory)
    ? lastSubcategory
    : firstSubcategory;
  if (subcategoryToShow == null) subcategoryToShow = product.categories?.[0];
  const usageType =
    usageList?.map((usage: string) => usage.split(' ')[0]) || [];
  const colorwaysNumber =
    product.colorVariants?.total_count > 1
      ? `${product.colorVariants?.total_count} ${internalLabels?.colorwaysLabel}`
      : null;
  const features = [
    priceLabel,
    subcategoryToShow,
    ...usageType,
    colorwaysNumber,
  ].filter((item) => item != null);
  const count = Number(childrenCount) > 1 ? childrenCount : undefined;
  const isNewLabel = isNew
    ? [{ label: internalLabels?.newLabel }]
    : isJustAdded
    ? [{ label: internalLabels?.justAddedLabel }]
    : isDigital
    ? [{ label: internalLabels?.digitalCatalogLabel, isDefaultColor: true }]
    : [];
  const blurhashString = blurhash?.length >= 6 ? blurhash : null;
  const shouldShowSelectOnHover =
    !isMobile && isSearchPage && shouldShowHoverSelect;
  const finalCardUrl = isProduct
    ? materialUrl?.split('?activeChild')?.[0]
    : materialUrl;
  const actionType: ProductCardActionType =
    isProduct || isDigital ? 'customAction' : 'addToCart';
  const showProjectsDropdown = !isMobile && !isProduct;
  const finishesCount =
    product.components?.reduce(
      (totalCount: number, finishGroup: { items: FinishGroupItem[] }) =>
        totalCount + (finishGroup.items?.length ?? 0),
      0,
    ) ?? 0;
  const finishesCountLabel = `${finishesCount} ${internalLabels?.finishes}`;
  const taxonomyLabel = product.taxonomy?.[0]?.split('>')?.pop();
  const subtitle1 = isProduct
    ? finishesCountLabel
    : subcategoryToShow || taxonomyLabel;
  const showColorVariants =
    shouldShowSelectOnHover && isSearch && isParentsShown && shouldShowVariants;

  const isAddToCartLoading = loadingCartItemIds?.includes(String(itemId));
  const actionButtonText = isDigital
    ? digitalButtonLabel
    : isGeoRestricted
    ? internalLabels?.seeSimilar
    : internalLabels?.sampleFinishesLabel;

  return (
    <ProductCard
      showAdvancedInfo
      showHoverFeatures
      showExtraInfo={false}
      isSmallDesktop={isSmallDesktop}
      hideActionButton={shouldHideActionButton}
      shouldHideFavoritesButton={shouldHideFavoritesButton}
      showOnHoverOnlyOvernight={showOnHoverOnlyOvernight}
      isCompactDescription={isCompactDescription}
      isSelectboxDisabled={isSelectboxDisabled}
      withColorVariants={showColorVariants}
      title={manufacturer}
      label={cardLabel}
      subtitle1={subtitle1}
      subtitle2={cardLabel}
      entityId={variantEntityId || entityId}
      imageUrl={variantImageUrl || imageUrl}
      projects={projects}
      productUrl={finalCardUrl}
      features={features}
      extraInfo={[]}
      topInfoItems={isNewLabel}
      isOvernight={isInRealTimeStock}
      isMobileDevice={isMobile}
      showSelectOnHover={shouldShowSelectOnHover}
      showSelect={isMultiselect}
      isHoverAllowed={isHoverAllowed}
      isOutlined={isOutlined}
      isSelected={isMultiselected}
      showBag
      childrenNumber={count}
      showProjectsDropdown={showProjectsDropdown}
      afterLabel={undefined}
      blurhash={blurhashString}
      actionType={actionType}
      actionButtonText={actionButtonText}
      onClickAddToFavorites={isMobile ? undefined : handleFavoritesClick}
      selectedProject={selectedProject}
      onAddToBag={handleAddToBoxClick}
      isAddToCartLoading={isAddToCartLoading}
      isAddedToCart={isInCart}
      onProductClick={handleProductClick}
      onActionButtonClick={handleActionButtonClick}
      onProjectClick={handleProjectClick}
      onSelected={handleItemSelect}
      onCreateProject={onCreateProject}
      onRightClick={handleRightClick}
      isDigital={isDigital}
      colorVariants={memoizedVariants}
      colorVariantsNumber={product.colorVariants?.total_count}
      onVariantClick={handleVariantClick}
      activeVariantId={variantEntityId}
      customTopRightButtonCallback={customTopRightButtonCallback}
      imageDecodeMode="lazy"
      {...finalLabels}
    />
  );
});

ResultCard.displayName = 'ResultCard';

export { ResultCard };
