import indexOf from 'lodash/indexOf';
import isObject from 'lodash/isObject';
import sortBy from 'lodash/sortBy';
import { config, testCustomerId } from '../../config';
import { extraFieldsList } from '../../hooks';
import { ProductSearchMaterial } from '../../types';
import { transformSearchspringResponseToAppFormat } from '../../utils';
import {
  getUserAttributes,
  getPayloadLang,
  getCartItems,
} from '../../utils/localstorage';

const customer = getUserAttributes();
const payloadLang = getPayloadLang();
const cartItems = getCartItems();

const localExtraFields = [
  ...extraFieldsList,
  'additional_images',
  'size_notes',
  'content_textile',
  'attachments',
  'is_digital',
  'configurations',
];

export const getMaterialsByKey = async (keyList: (string | number)[]) => {
  try {
    const body = {
      siteId: config.searchSpring.siteId,
      'key.entity_id': keyList,
      fieldsOnly: true,
      lang: payloadLang,
      extraFields: localExtraFields,
    };

    if (customer?.email != null) {
      // @ts-expect-error // TODO: fix types
      body['customerEmail'] = customer?.email;
    }
    if (customer?.customer_id != null) {
      // @ts-expect-error // TODO: fix types
      body['customerId'] = Number(customer?.customer_id) || null;
    }

    const response = await fetch(config.searchSpring.byKey, {
      method: 'POST',
      body: JSON.stringify(body),
    }).then((res) => res.json());

    const { results = [], ...rest } = response;
    const sortedResults = sortBy(results, function (obj) {
      return indexOf(keyList, String(obj.entity_id));
    });
    return {
      results: sortedResults,
      ...rest,
    };
  } catch (e) {
    console.log('Error getMaterialsByKey:', e);
    return null;
  }
};

export const getCompareMaterials = async (
  keyList: string[],
  xSessionid: string,
) => {
  try {
    const body = {
      siteId: config.searchSpring.siteId,
      id: keyList,
      lang: payloadLang,
      context: 'catalog',
      customer: {
        id: customer?.customer_id,
        email: customer?.email,
        projectId: Number(customer?.current_project),
        country: customer?.country,
        province: String(customer?.state || ''),
      },
      referrerUrl: window.location.host + window.location.pathname,
      sessionId: xSessionid,
      extraFields: localExtraFields,
    };
    let xRequestId: string | null = null;

    const response = await fetch(config.searchSpring.compare, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then((res) => {
      xRequestId = res.headers.get('x-request-id');
      return res.json();
    });

    const { results = [], ...rest } = response;

    // @ts-expect-error // TODO: fix types
    const resultsList = results.map((item) => {
      const newItem = transformSearchspringResponseToAppFormat(item);
      return {
        ...newItem,
        analyticsData: {
          analyticsClass: 'recommend',
          impressions: keyList,
        },
        isCompare: true,
        originalMaterial: item,
        originRequestId: xRequestId,
      };
    });

    const sortedResults = sortBy(resultsList, (item: ProductSearchMaterial) => {
      return indexOf(keyList, String(item.entityId));
    });
    return {
      results: sortedResults,
      ...rest,
    };
  } catch (e) {
    console.log('Error getCompareMaterials:', e);
    return null;
  }
};

export const getMaterialFirstChild = async (id: string) => {
  try {
    const data = new URLSearchParams();
    data.append('parent_id', id);
    const response = await fetch(config.searchSpring.getFirstChild, {
      method: 'POST',
      body: data,
    }).then((res) => res.json());
    return response?.child_id;
  } catch (e) {
    console.log('Error getMaterialFirstChild:', e);
    return null;
  }
};

interface PayloadFilter {
  field: string;
  value: string[];
}
export interface A1Payload {
  excludeFilters?: PayloadFilter[];
  includeFilters?: PayloadFilter[];
}

export const getRecommendStripA1 = async (payloadFilters?: A1Payload) => {
  try {
    const localStorageUser = localStorage.getItem('MSEARCH_TEST_CUSTOMER_ID');
    const userId = localStorageUser || testCustomerId || customer?.customer_id;
    // @ts-expect-error // TODO: fix types
    const cartItemIds = cartItems?.map((item) => item.product_id);
    const body = {
      customer: {
        id: userId,
        email: customer?.email,
        projectId: Number(customer?.current_project),
        country: customer?.country,
        province: String(customer?.state || ''),
      },
      useCase: 'userpersonalized',
      version: 0,
      context: 'catalog',
      siteId: config.searchSpring.siteId,
      referrerUrl: window.location.host + window.location.pathname,
      qty: 50,
      lang: payloadLang,
      includeFilters: [],
      extraFields: extraFieldsList,
      excludeItemIds: cartItemIds || [],
      forceFamily: false,
      diversifyFieldName: 'manufacturer',
    };

    let conditionedParams: any = {
      excludeFilters: [],
      includeFilters: [],
    };
    if (isObject(payloadFilters) && !Array.isArray(payloadFilters)) {
      conditionedParams = { ...payloadFilters };
    }

    let xRequestId: string | null = null;
    const response = await fetch(config.searchSpring.recommendStripA1, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...body, ...conditionedParams }),
    }).then((res) => {
      xRequestId = res.headers.get('x-request-id');
      return res.json();
    });

    const results = response?.[0]?.results;

    if (Array.isArray(results)) {
      const materials = results.map((item) => {
        const newItem = transformSearchspringResponseToAppFormat(item);
        return {
          ...newItem,
          originRequestId: xRequestId,
        };
      });
      return materials;
    }
    return [];
  } catch (e) {
    console.log('getRecommendStripA1', e);
    return [];
  }
};
