import { getMaterialsByKey } from '../api/materials';
import { ProductSearchMaterial } from '../types';
import { transformSearchspringResponseToAppFormat } from '../utils';

interface PreviewFileProps {
  product: ProductSearchMaterial;
}

// Trigger the preview file modal
export const triggerPreviewFileModal = async ({
  product,
}: PreviewFileProps) => {
  let currentProduct = product;
  const isParent = String(product.familyId) === String(product.entityId);

  if (!isParent) {
    const response = await getMaterialsByKey([product.familyId as string]);
    const formatedResultsWithData = (response?.results || []).map(
      transformSearchspringResponseToAppFormat,
    );
    currentProduct = formatedResultsWithData?.[0];
  }

  window.dispatchEvent(
    new CustomEvent('open-preview-files-dialog', {
      detail: {
        productId: String(product?.familyId) || String(product?.entityId),
        productSku: currentProduct?.sku,
      },
    }),
  );
};
